import React, { Component } from "react"
import styled from "styled-components"

const Form = styled.form`
  background-color: #ffffff;
  width: 338px;
  height: 500px;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: center;
`

const Input = styled.input`
  margin-top: 5px;
  width: 270px;
  border: none;
  font-size: 1.1rem;
  font-family: "overpass";
  outline: none;
  align-items: center;
  align-self: center;
  border-bottom: 1px solid #808080;
  padding: 10px;
`

const Button = styled.button`
  width: 205px;
  background-color: #19232d;
  color: #ffffff;
  font-size: 20px;
  font-weight: 900;
  border: 3px solid #19232d;
  text-transform: uppercase;
  padding: 10px;
  align-self: center;
  margin-top: 3rem;
  outline: none;
  &:hover {
    cursor: pointer;
    border: 3px solid #19232d;
    color: #19232d;
    background-color: #ffffff;
  }
`

const AnonymousHeader = styled.h2`
  width: 306px;
  height: 124px;
  left: calc(50% - 306px / 2 - 14.5px);
  top: 125px;
  font-family: Noe Display;
  font-size: 24px;
  line-height: 31px;
  display: flex;
  align-items: center;
  text-align: center;
`

const AnonError = styled.h2`
  width: 306px;
  left: calc(50% - 306px / 2 - 14.5px);
  top: 125px;
  font-family: Noe Display;
  font-size: 24px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #ff0000;
  outline: none;
`

export class AnonymousForm extends Component {
  constructor(props) {
    super(props)

    this.state = { email: "", username: "", passwordOne: "", passwordTwo: "" }
  }

  handleSubmit = () => {
    const { email, username, passwordOne, passwordTwo } = this.state

    email === "" || username === "" || passwordOne === "" || passwordTwo === ""
      ? this.setState({ error: `Bitte füllen Sie alle Felder aus` })
      : passwordOne === passwordTwo
      ? this.props.onLink(email, passwordOne, username)
      : this.setState({
          error: `Die eingegebenen Passwörter stimmen nicht überein`
        })
  }

  handleInputChange = ({ target }) => {
    this.setState({ [target.name]: target.value })
  }

  render() {
    const { email, username, passwordOne, passwordTwo, error } = this.state

    return (
      <Form onSubmit={this.handleSubmit}>
        {error ? (
          <AnonError>{error}</AnonError>
        ) : (
          <AnonymousHeader>Ändern Sie Ihr Gastkonto in ein normales Konto</AnonymousHeader>
        )}
        <Input
          name="username"
          value={username}
          onChange={this.handleInputChange}
          type="username"
          placeholder="Nachname und Vorname"
          autoComplete="username"
        />
        <Input
          name="email"
          value={email}
          onChange={this.handleInputChange}
          type="email"
          placeholder="Email"
          autoComplete="email"
        />
        <Input
          name="passwordOne"
          value={passwordOne}
          onChange={this.handleInputChange}
          type="password"
          placeholder="Passwort"
          autoComplete="first-password"
        />
        <Input
          name="passwordTwo"
          value={passwordTwo}
          onChange={this.handleInputChange}
          type="password"
          placeholder="Passwort weiderholen"
          autoComplete="second-password"
        />
        <Button type="submit">Link Email</Button>
      </Form>
    )
  }
}
