import React from "react"
import { AnonymousForm } from "../components/main/anonymousLink"
import Layout2 from "../components/main/layout2"

const AnonymousLinkPage = () => {
  return (
    <Layout2>
      <AnonymousForm />
    </Layout2>
  )
}
export default AnonymousLinkPage
